@import url(https://fonts.googleapis.com/css2?family=Merriweather:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
.loader-wrapper{
    z-index: 1000!important;
    background: #fff;
    height: 100%;
}
.container{
    display: flex;
    align-items: center;
    justify-content: center;
    
    text-align: center;
    color:#888;   
}
.loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #000;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
    
    
}
@-webkit-keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
label{font-size: 10px; margin-top:20px; display: block;}
.loader-logo{
    text-align: center;
    padding-top:80px;
}
.loader-logo img{width: 280px;}

.enappd-header {
  background: #0050c0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 15px;
}

.titleHeader {
  color: #ffffff;
  font-weight: bold;
  font-size: 20px;
}

ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}
ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}
ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header, ion-menu.md ion-note {
  padding-left: 10px;
}
/*
ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}
*/
ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;
  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;
  margin-bottom: 18px;
  color: #757575;
  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-item .selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;
  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
} 
:root {    
    --ion-color-primary: #000;
    --ion-color-primary-rgb: 0,102,0;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #000;
    --ion-color-primary-tint: #000;
    --ion-font-family: 'Merriweather', serif;
}
.swal-modal{color: #000;}


body{}
.build-version{ display: none;}

.enappd-header{background-color:var(--primary-color)}

a{color: #000;text-decoration: none;}
ul{padding:0;margin:0}
ul li{list-style: none; padding:0;margin:0}
a:hover{
    color:#000;
    text-decoration: underline
}
h1{
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.1rem;   
    color:var(--h1-color);
}
h2{font-weight: bold !important; color:var(--h2-color);}
h4{
    padding:0; margin:0;
    font-size: 16px;
    font-weight:bold;
    color:var(--h4-color);
}
h5{
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.1rem;    
    color:var(--h5-color);
}

.heading {
    padding-left: 16px;
    position: relative;
    border-bottom: 0.55px solid #c8c7cc;
    font-size: 16px;
    letter-spacing: 0.1rem;
    color: var(--primary-color);
    background: transparent;
    margin-bottom: 0px;
}
.router{ position: relative!important;}
.header ion-toolbar,.footer ion-toolbar {
    --background: #000;
    --color:#fff;
}
.footer ion-toolbar{padding:0 10px}
.footer ion-icon{
   zoom:1.1!important;
   color:#fff
}
.footer a{zoom:1.4}
.footer ion-badge {
    font-size: 10px;
}

.pull-bottom{
    position: absolute;
    bottom:0
}


.btn-menu{color:#fff}
ion-menu.md ion-list{padding:0}
.mob-info-bar {
    background: #d5d5d5;
    padding: 10px 0px;
    font-size: 11px;
    text-align: center;
}
.mob-info-bar .info-box-icon{ font-size: 14px; position: absolute; left:0; top:0}
.mob-info-bar .info-box{position:relative !important; padding:0 8px 0 18px }


.message{ padding: 10px 14px; font-size: 16px; color:red; border-radius: 10px; background-color: #f1f1f1; margin: 10px 10px; width: 100%;}

.font-12{font-size: 12px!important}
.font-13{font-size: 13px!important;}
.font-14{font-size: 14px!important;}
.font-16{font-size: 16px!important;}
.font-18{font-size: 18px!important;}
.disabled{background:#f1f1f1;}
.black{color:#000!important}

.cursor{ cursor: pointer !important;}
.cursor input,.cursor select{ cursor: pointer !important;}

/* input stepper control */
.stepper-input{ margin-top:3px}
.stepper-input .wrapper{padding: 4px 10px; border:1px solid var(--secondary-color); border-radius: 8px; width: 120px; position: relative; text-align: center;}
.stepper-input .wrapper .textbox{padding: 0;
    margin: 0 0px;
    outline: none;
    border: none;
    text-align: center;
    width: 70px;}
.stepper-input .wrapper ion-icon{zoom:1.3; cursor: pointer; top:4px; border-radius: 100px; position: absolute; color:#fff;z-index: 100;} 
.stepper-input .wrapper .inc{ background: var(--button-bg); right: 4px;}
.stepper-input .wrapper .inc:hover{background-color: var(--button-hover-bg);}
.stepper-input .wrapper .dec{background:var(--button-bg); left: 4px;}
.stepper-input .wrapper .dec:hover{background-color: var(--button-hover-bg);}

/*
.button-solid::part(native){background:var(--secondary-color)}
.ion-color-success::part(native){background:var(--primary-color)}
*/

.rdt_TableCell .stepper-input .wrapper{padding:6px 10px}

/* -- Home --*/  
.marquee {
    height: 50px;
    overflow: hidden;
    position: relative;
    background: #000;
    clear: both; 
}
.marquee p {
    position: absolute;
    font-size: 14px;
    color: #fff;
    width: 100%;
    height: 100%;
    margin: 0;
    line-height: 50px;
    text-align: center;
    -webkit-animation: scroll-left 30s linear infinite;
    animation: scroll-left 30s linear infinite;
}

@-webkit-keyframes scroll-left {
    0% {
        -webkit-transform: translateX(100%);
    }
    100% {
        -webkit-transform: translateX(-100%);
    }
}

@keyframes scroll-left {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}
.categories{ padding: 0 6px;}
.category {
    height: 180px;
    padding:15px 0 0 0;
    text-align: center;
    margin:0;
}
.dashboard{ clear: both;}
.dashboard .col-new-arrivals{}

.dashboard-link-more{position: absolute; right: 0; text-transform:capitalize ;}
/*  -- slider --  */
ion-slide{
    
}
.slider{
    
    
}
.slider2 .slider-image{
    height:250px
}
.slider .slider-card {
    width: 100%;
    padding-bottom: 0;
}
.slider .slider-image{
    height:200px;
    justify-content: center;
    display: flex;
    vertical-align: middle;
    align-items: center;
    min-width: 100%;
    font-size: 30px;
  color:white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; 
 }
 .slider h4{font-size: 16px; margin: 8px 0}
 .slider h5{font-size: 14px; margin: 6px 0}
.slider .image{}
.slider .link{cursor: pointer;}
.slider .video{ cursor: pointer;}
.slider .watermark{
    position: absolute;
    bottom: 0;
    right:10px;
}
.slider .watermark ion-icon{zoom: 1.7!important; color:#e1e1e1; fill:#e1e1e1!important}
.banner .slider-card{height:584px}
.banner .slider-image{height: 100%;}
.banner .slider-image img{height: 100%; width:100%}
.swiper-pagination-bullet{background-color:#fff}

.new-arriaval{  }


.home-page-Dcontent {padding:0 14px}

.d-content{}
.d-content ul{ margin:4px 0px 4px 12px }
.d-content ul li{list-style: disc; padding:0px 4px;margin:0px 2px}

/*  product listing */
.product-list{margin:4px 6px; border-radius: 10px;}
.product-list .product-content{padding:10px 20px}
.product-list .p-img,.product .p-img{
    height: 280px;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    border-radius:10px
}
.product-list ion-card-content{padding:0}
.product-list ion-card-content ion-col{padding:2px 0}
.product-list ion-card-content ion-badge{font-size: 13px;}
.product-list .video-available span {
    font-size: 11px;
    float: right;
    font-weight: bold;
    padding: 10px 0 0 0;
    color: #ff0000;
}
.product-list .video-available ion-icon {
    zoom: 1.8;
    padding: 3px 4px 0px 0;
    color: #ff0000;
}
.product-list ion-card-content .note{ height: 18px;
    font-size: 14px;
    color: #ff0000;
    font-family: inherit;
    font-weight: bold;
    font-style: italic;
    text-align: center;}
.product-list .name{padding-top:14px}
.product-list .desc{color:var(--secondary-color); font-size: 12px; font-weight: 600; letter-spacing: 1px;}
.product-list .cat{font-weight: 600; letter-spacing: 1px;}
.product-list .btn{font-size: 12px;}

ion-button::part(native){background:var(--button-bg); color: var(--button-font);}
ion-button ion-icon{fill: var(--button-font); color: var(--button-font)}
ion-button:hover::part(native){background:var(--button-hover-bg); color: var(--button-hover-font);}
ion-button:hover ion-icon{fill: var(--button-hover-font); color: var(--button-hover-font)}


.product-list .product-stock{
    position: absolute;
    right: 0;
    border-radius: 4px;
    padding: 2px 6px;
    font-size: 11px;
    color: #fff;
    top: 220px;
}
.stockinout-0{background:#ff0000d4}
.stockinout-1{background:#000}


.img-not-available{
    background-size: contain!important;
}
.img-viewer{clear: both; overflow: auto;}
.img-viewer .left{width:12%;float:left; padding-right: 2%;}
.img-viewer .left img{margin-bottom: 10px; border-radius: 6px; cursor: pointer;  }
.img-viewer .left ion-icon{
    fill: #fff;
    width: 100%;
    color: #fff;
    zoom: 2;
    height: 18px;
    background: red;
    border-radius: 4px;
    cursor: pointer;
    
}
.img-viewer .right{float:left;width:88%}
.img-viewer .right img{cursor: pointer!important;}
.img-viewer-modal{
    position:fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #262626de;
    z-index: 100000;
  
}
.img-viewer-modal .modal-container{ position: relative; margin:auto;max-width: 900px; margin-top:60px ;}
.img-viewer-modal ion-icon.close{position: absolute;right:-16px;top:-14px;zoom:3;fill:#fff; cursor: pointer;}
.img-viewer-modal .left{}
.img-viewer-modal .left img,.img-viewer-modal .left ion-icon{ border:1px solid #e1e1e1}
.img-viewer-modal .right img {width:100%; border:1px solid #e1e1e1}

/* image viewer modal */
.img-viewer-modal .main-screen{  clear: both; display: flex; justify-content: center; min-height: 500px; background: #fff; padding:4px; border-radius: 6px; }
.img-viewer-modal .main-screen .content{ margin: auto; width: 100%; height: 100%;}
.img-viewer-modal .main-screen img{margin: auto;}
.img-viewer-modal .main-screen .content iframe{width: 100%;}
.img-viewer-modal .thumbnails-list{ margin: 0 0 4px 0; clear: both; display: flex; justify-content: center;}
.img-viewer-modal .thumbnails-list div {
    width: 70px;
    height: 70px;
    padding: 4px;
    background: #fff;
    margin: 2px;
    border-radius: 6px;
    display: flex;
    cursor: pointer;
}



.quick-order .rdt_TableHead{border-bottom: 1px solid #000;border-top: 1px solid #e1e1e1;border-right: 1px solid #e1e1e1;}
.quick-order .rdt_TableHead .rdt_TableCol_Sortable{padding-left: 10px;}
.quick-order .rdt_TableHead .rdt_TableCol{border-left: 1px solid #e1e1e1;}
.quick-order .rdt_TableCell{border-left: 1px solid #e1e1e1;}
.quick-order .rdt_TableCell:last-child{border-right: 1px solid #e1e1e1;}
.quick-order .rdt_TableCell{padding-left:10px}
.quick-order-detail .img-viewer .left{width:70px}
.quick-order-detail .img-viewer .right{width:88%}
.quick-order-detail .img-viewer .right img{width:400px}
.quick-order-detail .img-viewer-modal .right img{width:auto}
.quick-order .filter-container{padding: 11px 14px;
    border: 1px solid #e1e1e1;
    margin: 10px 10px;
    border-radius: 8px;}


.product .p-web-img{
    background-size: cover;
    background-position: center center;
    border: 1px solid #e1e1e1;
    padding: 2px;
    border-radius: 10px;
    
}
.product .p-img{max-height: 300px !important;
    border-bottom: 3px solid #ffffff;}
.product .price-cat{
    border-top: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    padding: 8px 0;
    margin: 8px 0;
}




 /*    Web Desktop  */
.web{
    background:#fff;
  }
    .web .web-width, .web .page-content{
        max-width: 1280px; margin: auto; padding:0 0px; position: relative;
    }
    .web .page{
        box-shadow: 0px 0px 29px -16px rgba(0,0,0,0.75);
        padding:0;
    }
    .web-header{
        
    }
    .web-header .top-bar{
        width: 100%;
        background: var(--primary-color);
        position: relative;
        margin-bottom: 0px;
        height:38px
    }
    .web-header .top-bar .menu {
        padding-top:8px
    }
    .web-header .top-bar .menu ul li{ list-style: none; float: left; cursor: pointer;}
    .web-header .top-bar .menu ul li a{
        padding: 0px 5px;
        color: var(--font-default)!important;
        text-decoration: none;font-weight: bold;
        font-size: 12px;}
    .web-header .top-bar .menu ul li a:hover{
        color: var(--secondary-color) !important;
        text-decoration: underline
    }
    .web-header .top-bar .social {
        float:right;
        zoom:1.2;
    }
    .web-header .top-bar .social ion-icon{fill:var(--font-default); margin-right: 4px;}
    .web-header .top-bar .social ion-icon:hover{fill: var(--secondary-color);}
    .web-header .middle-bar{
        height:80px
    }
    .web-header .logo {
        padding:20px 0px
    }
    .web-header .info {
        font-family: 'Oswald', sans-serif;
        overflow: auto;
        font-size: 16px;
        padding: 17px 0;
    }
    .web-header .info p{ float: left; line-height: 20px; margin: 0; color:#fff; font-weight: bold; letter-spacing: 2px;}
    .web-header .info span {
        padding: 0 10px;
        display: block;
        color:#fff;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1px;
    }
    .web-header .info ion-icon {
        font-size: 19px;
        margin-top: -3px;      
        margin-right: 7px;
    }
    .web-header .info span{padding:0 10px}
    .web-header .top-cart{
        cursor: pointer;
        position: absolute;
        top: 24px;
        right: 28px;
        z-index: 1000;
    }
    .web-header .top-cart{
        border:1px solid #e1e1e1;
        padding:4px 6px; border-radius: 4px;
    }
    .web-header .top-cart ion-icon{
        zoom:1.3;
        fill:var(--primary-color)
    }
    .web-header .top-cart:hover ion-icon,.web-header .top-cart:hover{color: var(--secondary-color);fill: var(--secondary-color);}
    .web-header .top-cart span {
        vertical-align: super;
        font-size: 12px;
        padding-left:4px;
    }
    /* model ProductSearch */
    .web-header .search {
        position: absolute;
        z-index: 1;
        top: 24px;
        left: 420px;
        max-width: 500px;
        width: 36%;
        min-width: 300px;
        cursor: pointer;
    }
    .web-header .search input{
        border:1px solid #e1e1e1;
        padding:10px 50px 8px 10px;
        font-size: 13px;
    }
    .web-header .search ion-icon{
        position: absolute;
        right: 0px;
        top:0px;
        zoom: 1.4;
        border:1px solid #e1e1e1;
        padding:4px 6px;
        cursor: pointer;
        z-index: 1000;
        background: #fff;
        
    }
    /*.web-header .search-modal{font-size: 14px;}
    .web-header .search-modal ion-searchbar{padding:3px 0px; margin-bottom: 10px;}
    .web-header .search-modal .form{padding:10px}
    .web-header .search-modal slot{font-size: 12px;}
*/
/*  -- web menu --  */


  .main-nav {
    position: relative;
    z-index: 200;
    background: var(--main-menu-bg);
    height: 66px;
    padding-top: 14px;
  }
  #menu {}
  .main-nav li a{cursor: pointer;}
  .main-nav li a.no-link{cursor:cell}
  .main-nav li a.no-link{cursor:cell}
  .main-nav li.parent {display: inline-block; position: relative;}
  .main-nav li.parent > a {
    
    font-weight: bold;
    text-decoration: none;
    padding: 10px 10px;
    font-size: 13px;
    display: block;
  }
  .main-nav .level0:hover > a{
    border-radius: 10px !important;
  }
 
  .main-nav .level0 a{color:var(--main-menu-font)}
  .main-nav .level0 a:hover{color:var(--main-menu-font-hover); background-color: var(--main-menu-bg-hover);}
  /*.main-nav li.parent:hover > a {background-color: var(--main-menu-bg-hover); color: var(--secondary-color)}
  .main-nav li.parent li:hover > a{color: #fff!important}
*/
  .main-nav li.parent:hover > ul {display:block; position:absolute; }
  .main-nav .child a{padding: 8px 10px;}
  .main-nav .child {display: none;}
  .main-nav .child li {background-color: var(--child-menu-bg); border-bottom:var(--child-menu-divider-line) 1px solid; border-right:none; width:100%; clear: both;}
  .main-nav .child li a{ font-size: 12px; color: var(--child-menu-font); }
  .main-nav .child li a:hover{ background-color: var(--child-menu-bg-hover) ; color: var(--child-menu-font-hover);}

  .main-nav ul{list-style: none;margin: 0;padding: 0px; min-width:10em;}
  .main-nav ul ul ul{left: 100%;top: 0;margin-left:0px;}
  .main-nav .expand{font-size:12px;float:right;margin-right:5px;}
  .main-nav > ul > li:last-child{border: 0!important;}
  
  .bread-crumbs{ color:#888; font-size: 14px;}
  .bread-crumbs a{color:var(--primary-color)}
  .bread-crumbs span{background-color: #e1e1e1; font-size: 11px; padding: 2px 6px; border-radius: 4px; font-weight: bold;}
  


.mega-menu{}
.mega-menu-wrapper{  
    display: none;
    position: absolute;
    top:0px;
    left:0;
    right:0;
    z-index: 1;
}
.mega-menu-content{
    background-color: #efefef;  
    min-height: 400px;
    border-radius: 6px;
    display: flex;
    padding:30px 40px;
    z-index: 0;
}
.mega-menu-content .column {
    padding: 0 30px;
    border-right: 1px solid var(--secondary-color);
}
.mega-menu-content .col-4{width: 25%;}
.mega-menu-content .col-2{width: 50%;}
.mega-menu-content .col-3{width: 33.33%;}
.mega-menu-content .col-1{width: 100%;}
.mega-menu-content .column:last-child{border:0!important}

.mega-menu-content ul{ font-weight: bold; padding:0 0 4px 0}
.mega-menu-content ul li{font-weight:300; padding:4px 0 2px 10px;}
.mega-menu-content ul li:last-child{padding-bottom:10px}
.mega-menu-content ul a{ color:var(--font-default); font-size: 13px; cursor: pointer;}

.web-footer {
    padding: 20px;
    background: var(--primary-color);
    color:var(--font-default);
    font-size: 14px;
    margin-top: 20px;
}
.web-footer a{color:var(--fonr-highlighted)}
.web-footer a:hover{color:var(--secondary-color)}
.web-footer h4{ margin-bottom: 20px;}
.web-footer .desc{font-size: 12px; font-family:sans-serif; padding: 5px 50px 5px 0; }
.web-footer .info {color:#e3e3e3; clear: both; margin-bottom: 10px; font-size: 12px;}
.web-footer .info ion-icon{ 
    float: left;
    zoom: 1.3;
    padding: 0;
    margin: 0 8px 0 0;
}
.web-footer .links{margin:0; padding:0}
.web-footer .links li{margin:0; padding:0;}
.web-footer .links li a{color:var(--font-default); display: block; font-size: 12px; padding: 4px 0;}
.web-footer .links li a:hover{ color:var(--secondary-color)}
.web-footer .links ion-icon{ margin-right: 8px; zoom:1}

.web-footer .copyright{ font-size: 12px;}
.web-footer .social{ zoom: 1.6; text-align: right;}
.web-footer .social a{ margin: 0 2px;}
.web-footer .featured{display: flex; flex-direction: row;  }
.web-footer .featured div{width:154px; height: 154px;  background-color: #fff; padding:2px; border-radius: 5px; margin: 0 6px; overflow: hidden; cursor: pointer; display: flex; justify-content: center}
.web-footer .featured div img{width:150px; height: 150px;  }




/*   form controls  */
.form ion-item {--border-width:0}
.form .item-interactive {
    --highlight-background: unset;
    --background-focused:unset
}
.form input{font-size:13px!important;}
.form-control{border: 1px solid #dad9d9; }
.form ion-item:last-child {--inner-border-width: 0;} /* inner control border */
.form ion-item {--inner-border-width: 0;} /* inner control border */
ion-input.form-control,ion-textarea.form-control {padding: 0px 8px!important;margin-top: 10px;border-radius: 6px; font-size: 14px; }
select.form-control{padding: 6px 6px!important;margin-top: 10px;border-radius: 6px; font-size: 14px; }
ion-checkbox.form-control {border:0!important; }
ion-radio.form-control {border:0!important; }
ion-select.form-control{border-radius: 6px; }
.form .form-control.error {border:1px solid red; color:red}
.form .error .form-control{border:1px solid red; color:red}
.form .error span{ color:red}

.no-border{border:0!important;}

.image-preview{
    margin-top: 20px;
    border:1px solid #e1e1e1;
    height: 250px;
    width: 100%;
    padding:10px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    
}
.image-preview ion-fab-button{
    position: absolute;
    top: 20px;
    right: -5px;
}

.file-upload{
    margin: 0;
    position: relative;
    overflow: hidden;
    display: inline-block;
}
input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}
.file-upload ion-icon{zoom:1}


.player-modal .modal-wrapper  {
    /*position: absolute;
    top: 0 !important;
    left: 0 !important;
    display: block;
    width: 100% !important;
    height: 100% !important;*/
} 

/*  shopping cart */
.cart-wrapper{ min-height: 300px;}
.summary{
    padding: 10px 10px;
    background: #f1f1f1;
    margin-bottom: 10px;
    margin-top: 13px;
    border: 1px solid #e1e1e1;
}
.cart-wrapper .summary h3{font-weight: bold; padding-bottom: 10px;}
.cart{padding:12px 0px; margin-bottom: 10px;border-bottom: 1px solid #f1f1f1; overflow: auto; position: relative;}
.cart .detail{ padding-top: 10px;}
.cart-wrapper ion-badge{padding:4px 6px; width:90px}
.cart h2{ font-weight: bold;}
.cart-wrapper .value{ background: transparent!important;color: #000; margin-right: 10px; text-align: left; width: 50px;}
.cart .remove{  position: absolute; bottom:10px; left:0px; cursor: pointer; zoom: 1.42;}
.cart .icon-view-detail{ position: absolute; bottom:0;right:0; zoom: 1.8;cursor: pointer; z-index: 999;}
.order-detail{padding:0 10px;}
.order-detail h3{font-size: 16px !important;}
.cart .odate{ font-size: 12px; float: right;}
/* Menu shopping cart  */
.cart-menu{ font-size: 14px;}
.cart-menu .item-list {
    padding: 10px 0;
    position: relative;
    overflow: auto;
    border-bottom: 1px solid #e1e1e1;
}
.cart-menu .item-list:first-child{padding-top:0}
.cart-menu .item-list img {
    width: 80px;
    padding-right: 10px;
    float: left;
}
.cart-menu .item-list p{margin: 0;padding-bottom: 12px;}
.cart-menu .item-list span{background: #e1e1e1;
    padding: 3px 8px;
    font-size: 11px;
    border-radius: 10px;}
.cart-menu .item-list ion-icon {
    position: absolute;
    right: 5px;
    bottom: 6px;
    zoom: 1.4;
    cursor: pointer;
}   
.cart-menu .sub-total{border-bottom: 1px solid #e1e1e1;  padding: 10px 0; margin-bottom: 10px;height: 36px;}
.cart-menu .sub-total ion-label{float: left; font-weight: normal;}
.cart-menu .sub-total{clear: both; text-align: right; font-weight: bold;}

/* confirmation modal buttons */
.swal-button:hover{background: #000 !important;}
.swal-button--true{ background:red }
.swal-button--false{background:#757575}

.filter{ padding:20px 10px; background: #f1f1f1;  }
.filter ion-icon{float: right; zoom:1.4}

/*  Admin */
.search-panel .form-control{margin-top:4px;padding:8px 8px 7px 15px}
.search-panel ion-input{height:37px!important}


/* admin dahsboard*/
/*.admin-nav a{ 
    padding: 8px 0px;
    background: var(--primary-color);
    color: var(--secondary-color);
    border-radius: 10px;
    margin-bottom: 6px;
    display: block;
    text-align: center;
    letter-spacing: 2px;
    font-size: 16px;
    text-decoration: none;
 }
 .admin-nav a:hover{ background:var(--secondary-color); color:var(--primary-color)}
*/
/* image cropper  */
 .cropper-modal{padding:10px}
 .cropper-modal .cropper{  position: relative;min-height: 300px;}

 /* admin web-menu */
.web-menu{ font-size: 16px; }
.web-menu .wrapper{ background-color: #f1f1f1; padding:10px 0px; border-radius: 10px;}
.web-menu ul{ margin: 0;padding:0px 10px;}
.web-menu ul ul{margin:0 0px 0 24px}
.web-menu ul li{cursor: pointer;}
.web-menu .selected {
    background: #03A9F4;
    border-radius: 5px;
    color: #fff;
    padding: 0px 0px 0px 4px;
}

.large-modal ion-checkbox{
    border-width: 1px !important;
    border-radius: 4px;
    zoom:0.7
}
.large-modal ion-item{
    zoom:1;
    font-size: 14px;
    margin: 0;
}

.large-modal .modal-wrapper{    
    --height: 740px;
    --backdrop-opacity:0;
    --background:#bdbdbd45    
}
.large-modal ion-content::part(scroll){
    background: #e1e1e1;
}
.webmenu-builder{}
.webmenu-builder .options{  
    margin-right: 0;
    margin-left: 0;
    background: #e1e1e1;
    padding: 1px 10px;    
    position: relative;
    overflow: auto;
 }
 .webmenu-builder .optons-container{ width: 100%; }
.webmenu-builder .options label{background:#f1f1f1; width: 80%; border-radius: 10px; font-size: 14px;  padding:4px 10px;margin-bottom: 6px;}
.webmenu-builder .options ion-input{font-size: 14px;}
.webmenu-builder .options ion-icon{
    zoom: 1.6;
    padding: 5px;
    cursor: pointer;
    border-radius: 20px;
    margin:0 2px
}
.webmenu-builder .options ion-icon.selected{background:#f1f1f1}
.webmenu-builder .options ion-icon:hover{background:#f1f1f1}

.swal-text{text-align: center;}


.my-accoumt-menu{padding: 20px 0;}
.my-accoumt-menu a{
    padding: 7px 10px;
    display: block;
    background: #f1f1f1;
    text-decoration: none;
    border-radius: 10px;
    text-align: center;
    letter-spacing: 1px;
    font-size: 15px;
    margin: 0 10px;
}

.checkout-form{}
.checkout-form h2{padding-bottom: 10px;border-bottom: 1px solid #e1e1e1;}
.checkout-form ion-item {
    --padding-start: 0;
    --inner-padding-top:0;
}    
.form-steps {
    position: relative;
    padding: 25px 0;
    text-align: center;
    height: 70px;
}
.form-steps .step {
    width: 50%;
    float: left;
    border-top: 2px solid #e1e1e1;
    position: relative;
    padding-top: 16px;
    font-size: 12px;
    /* padding-bottom: 22px; */
}
.form-steps .step span{position: absolute;
    position: absolute;
    left: calc(50% - 15px);
    border-radius: 20px;
    background: #e1e1e1;
    padding: 5px;
    height: 28px;
    width: 28px;
    top: -17px;}
.form-steps .step.active{border-color:#3980ff;}
.form-steps .step.active span{background:#3980ff!important; color:#fff}
.checkout-form .options{
    position: relative;
    text-align: center;
    padding:24px 10px 14px 10px;
    min-height: 110px;
    border:1px solid #e1e1e1;
    cursor: pointer;
    line-height: 14px;
}
.checkout-form .options img{width: 40px; margin: auto; display: block; margin-bottom: 10px; }
.checkout-form .options.active{
    background:#0063d3;
    color:#fff;
}
.checkout-form .options.active img{
    filter: invert(1);
    -webkit-filter: invert(1);
    -moz-filter: invert(1);
}
.address-type-form{background:#f1f1f1; padding:8px; margin:  0 5px; overflow: auto;}
.address-type-form ion-item{--background:#f1f1f1}
.address-type-form ion-label{ }
.address-type-form .form-control{ border:2px solid #999999 }
.address-type-form ion-select{ border:2px solid #999999; border-radius: 6px; margin-top: 10px !important; }
.address-type-form ion-textarea{}
.checkout-form .note{font-size: 12px; clear: both; padding:5px 0}
.checkout-form h3{font-size: 15px;margin:4px 0}

.general-sales-rep ion-avatar{width:120px;height:auto;margin-right:10px}
.general-sales-rep ion-avatar img{border-radius: 0;}

.custom-list-control{border:1px solid #e1e1e1;padding:12px 10px 8px 10px; font-size: 13px; margin-top:4px; border-radius: 5px; cursor: pointer;}
.custom-list-control ion-icon{position: absolute; right:13px;top:22px}

.custom-list{ 
    width: 290px;height: 290px;
    font-size: 14px;
    border: 1px solid #e1e1e1;
    position: absolute;
    z-index: 100!important;
    background: #fff;
    border-radius: 4px;
    overflow: auto;}
.custom-list input {
    height: 30px;
    width: 256px;
    margin-left: 9px;
    margin-bottom: 6px;
    border-radius: 5px;
    outline: none;
}
.custom-list .item{padding:4px 10px; cursor: pointer;}
.custom-list .item.parent,.custom-list .item.parent:hover{}
.custom-list .item:hover{background:#f1f1f1}
.custom-list .default{background: #888;
    color: #fff;
    padding: 2px 10px; cursor: pointer;}
.custom-list .default:hover{background:#888;color:#fff}

/*.custom-list .arrow-up,.custom-list .arrow-down{ background:#e1e1e1;height:16px; text-align: center; cursor: pointer; }
.custom-list .arrow-down{position: absolute; width:100%;bottom:0}
.custom-list .arrow-up.active,.custom-list .arrow-down.active{background:#888}
.custom-list .disabled{background:#f6f6f6 !important}
.custom-list .disabled ion-icon{color:#c6c6c6}
*/

@media only screen and (max-width: 450px) {
    .new-arriaval img{width:auto;height:auto}
    .banner .slider-card{height: auto;}
    .home-page-Dcontent table{}
    .home-page-Dcontent table tr{}
    .home-page-Dcontent table tr td{display: flex;width:100%!important}

    .dashboard{}
    .dashboard .col-new-arrivals{ order: 2;}

    .round #tawkchat-minified-wrapper {
        bottom: 32px!important;
        width: 30px!important;
        height: 30px!important;
    }
    #tawkchat-status-text-container svg {
        width: 16px!important;
        height: 16px!important;
        margin-top: 8px!important;
    }
}

.paging{overflow: auto; clear: both; padding:10px; border-top:1px solid #f1f1f1;}
.pagination{float: right;}
.pagination li{ float: left;}
.pagination li.disabled{ background:#f1f1f1;}
.pagination li a{ 
    padding:6px 10px; display: block; 
    border-top:1px solid #e1e1e1;
    border-bottom:1px solid #e1e1e1;
    border-left:1px solid #e1e1e1; 
    font-size: 16px;
    text-decoration: none;
}
.pagination li a:hover{background-color: var(--button-hover-bg); color:var(--button-hover-font)}
.pagination li:last-child a{border-right: 1px solid #e1e1e1;}
.pagination li.disabled a{background:#f1f1f1; color:#e1e1e1 }
.pagination li.disabled a:hover{background:#f1f1f1; color:#e1e1e1 }
.pagination li.active a{background:var(--button-bg); font-weight: bold; color:var(--button-font)}
.rdt_TableHeadRow div{font-weight: bold;}


table, td, th {
    border-collapse: collapse;
    border-color: #f1f1f1;
    border-style: solid;
}
table {
    border-width: 1px 0 0 1px;
    font-size: 13px;
    margin: 2em 0;
    overflow-x: auto;
    width: 100%;
}
td, th {
    border-width: 0 1px 1px 0;
    line-height: 1.5;
    padding: 1em;
    text-align: left;
}
table, td, th {
    border-collapse: collapse;
    border-color: #e1e1e1;
    border-style: solid;
}
thead tr {
    color: #ececec;
    background: #000;
}
tbody tr:nth-child(odd) {
    background-color: #f7f7f7;
}

.instagram-feed{ background: #f3f3f3;
    padding: 20px 0px 4px 0px;
    margin-top: 20px;}
.instagram-feed .slider-image{}

.mobile-menu-product{ }
.mobile-menu-product .menu_children{ padding-left:14px ;border:0 }
.mobile-menu-product .m-icon {zoom: 0.6;}
.mobile-menu-product > div > ion-item:first-child .m-icon {zoom: 1!important;}
.mobile-menu-product ion-item{ padding:0px}
.mobile-menu-product ion-item{/*border-bottom-left-radius: 0!important;border-bottom-right-radius: 0!important;*/}
.mobile-menu-product .on{--background:#9b9b9b;--color:#fff}
.mobile-menu-product .on ion-icon{color:#fff}
.mobile-menu-product .on ion-icon:last-child{transform: rotate(90deg);}


.page {
    
    left: 15px;
    right: 15px;
}
.page-enter {
    opacity: 0;
    transform: scale(1);
}
.page-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 1300ms;
}
.page-exit {
    opacity: 1;
    transform: scale(1);
}
.page-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 1300ms, transform 1300ms;
}

span.cross-price {
    text-decoration: line-through; font-size: 13px;
}
span.price-type{
    position: absolute;
    top: 7px;
    right: 0;
    background: #ff0000d4;
    padding: 3px 9px;
    color: #fff;
    border-radius: 4px; 
    font-size: 14px;
    font-weight: bold;
}
span.price-type.detail{
    top:150px!important;
}
.cart-wrapper span.price-type{
    font-size: 10px;
    padding: 1px 4px;
}
.cart-menu span.price-type{
    font-size: 8px;
    padding: 2px 6px;
    position: relative;
    background: #ff0000d4;
}
.product-notes{
    background-color:#FF9800!important
}

span.price-type-1{
    top: 7px;
    left: 0;
    right:auto;
}
span.price-type-2{
    top: 7px;
    left: auto;
    right:0;
}
span.price-type-3{
    top: 246px;
    left: auto;
    right:0;
}
span.price-type-4{
    top: 246px;
    left: 0;
    right:auto;
} 
span.price-type-5 {
    top: 130px;
    left: auto;
    right: 0;
}

.categories{ position: relative;}
.categories .cat-image{height:200px; border-radius: 10px; background-position: center center; background-size: cover; cursor: pointer;}
.categories label {
    font-size: 20px;
    text-align: center;
    background: #ffffff96;
    position: absolute;
    bottom: 20px;
    color: black;
    font-weight: bold;
    width: 100%;
    padding: 5px 0;
    cursor: pointer;
    letter-spacing: 2px;
}

.cart-tracker-detail{background:#e1e1e1}
.cart-tracker-detail .product-list{padding:10px}
.cart-tracker-detail h2{font-size: 12px;}


.slider-container{ position:relative; overflow: hidden;  height:140px;
    background:url(https://merakigemstones.com/images/misc/logo.png) center center no-repeat #aaaaaa;
    background-size: 40%;
}

.slider-container .slider-banner{ width:100%; position: absolute; opacity: 0;  }
.slider-banner .slider-image img{ width:100%;}
.slider-banner .slider-video {width:100%}


.fade-in {
    opacity: 1;
	-webkit-animation-name: fadeInOpacity;
	        animation-name: fadeInOpacity;
	-webkit-animation-iteration-count: 1;
	        animation-iteration-count: 1;
	-webkit-animation-timing-function: ease-in;
	        animation-timing-function: ease-in;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    z-index: 100;
}
@-webkit-keyframes fadeInOpacity {
	0% {
        opacity: 0;
    }
	100% {
        opacity: 1;
	}
}
@keyframes fadeInOpacity {
	0% {
        opacity: 0;
    }
	100% {
        opacity: 1;
	}
}
.fade-out {
    opacity: 0;    
	-webkit-animation-name: fadeOutOpacity;    
	        animation-name: fadeOutOpacity;
	-webkit-animation-iteration-count: 1;
	        animation-iteration-count: 1;
	-webkit-animation-timing-function: ease-in;
	        animation-timing-function: ease-in;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    z-index: 10;
}
@-webkit-keyframes fadeOutOpacity {
	0% {
        opacity: 1;
	}
	100% {
        opacity: 0;
	}
}
@keyframes fadeOutOpacity {
	0% {
        opacity: 1;
	}
	100% {
        opacity: 0;
	}
}

.slider-bullets {
    position: absolute;
    bottom: 0;
    z-index: 100;
    width: 100%;
    color: #fff;
}
.slider-bullets ul {text-align: center;}
.slider-bullets li {
    display: inline;
    padding: 2px;
    text-align: center;
    cursor: pointer;
}

.slider-bullets .active{color:#f70201; cursor: text;}

.by-shapes-nav .box{background:#e1e1e1; height: 100%;}
.by-shapes-nav .box .links{ padding:10px 10px; height: 100%;}
.by-shapes-nav strong{
    display: block;
    background: black;
    padding: 6px 0;
    color: #fff;
    text-align: center;
}
.by-shapes-nav a {
    display: block;
    padding: 2px 0px;
    border-radius: 21px;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
  }



.mega-menu-designer{}
.mega-menu-designer .column {
    background: #e1e1e1;
    padding: 10px 4px 4px 4px;
    position: relative;
    margin: 60px 4px 4px 4px;
}
.mega-menu-designer .add-node {
    zoom: 2.2;
    color: green;
    cursor: pointer;
    position: absolute;
    left: -4px;
    top: -14px;
}
.mega-menu-designer .remove-col {
    zoom: 2.2;
    color: red;
    cursor: pointer;
    float: right;
}

.mega-menu-designer .column .section0 {
    background: #fff;
    margin-bottom: 11px;
    border-radius: 8px;
}
.mega-menu-designer .section a{cursor: pointer;}
.mega-menu-designer .parent {
    border-bottom: 1px solid #d4d4d4;
    padding: 6px 10px;
    font-weight: bold;
}
.mega-menu-designer .child {
    padding: 6px 10px;
    margin: 0 0 0 10px;
}
.mega-menu-designer .node:hover{ background: #f1f1f1;}
.mega-menu-designer .node ion-icon{
    float: right;
    zoom:1.5;
    cursor: pointer;
}
.mega-menu-designer .node .add{color: green;}
.mega-menu-designer .node .del{color: red;}


fieldset{ border: 1px solid #e1e1e1; margin-bottom: 10px;}
fieldset legend{ padding: 4px 10px; }







.mobile-menu {
    border-top: 2px solid var(--main-menu-font);
}
.mobile-menu .menu-item a{
    background: var(--main-menu-bg);
    color: var(--main-menu-font);
    border-bottom: 1px solid var(--main-menu-font);
    display: block;
    padding: 9px 10px;
    
}
.parent-v-mega-menu{
    background: var(--main-menu-bg);
    border-bottom: 1px solid var(--main-menu-font);
    display: block;
}
.mobile-menu .hide{display: none;}
.v-mega-menu {} 

.v-mega-menu > a {
    font-weight: bold;
    font-size: 14px;
    padding-left:20px !important
}
.v-mega-menu li{} 
.v-mega-menu li a {
    padding-left:30px !important
}
