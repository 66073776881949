.loader-wrapper{
    z-index: 1000!important;
    background: #fff;
    height: 100%;
}
.container{
    display: flex;
    align-items: center;
    justify-content: center;
    
    text-align: center;
    color:#888;   
}
.loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #000;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
    
    
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
label{font-size: 10px; margin-top:20px; display: block;}
.loader-logo{
    text-align: center;
    padding-top:80px;
}
.loader-logo img{width: 280px;}
